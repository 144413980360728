<template>
    <SplitGrid
      ref="outerGrid"
      :gutter-size="3"
      direction="row"
      class="flex flex-1 w-full h-full overflow-hidden bg-neutral-gray"
      :min-size="gridBottomMin"
      @drag-end="onDragEnd"
    >
      <SplitGridArea
        :size-unit="gridTopUnit"
        :size-value="gridTop"
      >
        <SplitGrid
          ref="innerGrid"
          :min-size="colSizeMin"
          :gutter-size="3"
          direction="column"
          class="flex flex-1 w-full h-full overflow-hidden bg-scene-gray"
          @drag-end="onDragEnd"
        >
          <SplitGridArea
            size-unit="%"
            :size-value="gridL"
          >
            <slot name="left" />
          </SplitGridArea>

          <SplitGridGutter
            v-if="showLeftGutter"
            class="z-50"
          />

          <SplitGridArea
            size-unit="%"
            :size-value="gridC"
          >
            <slot name="viewer" />
            <slot name="inspector"/>
          </SplitGridArea>

        </SplitGrid>
      </SplitGridArea>

      <SplitGridGutter class="z-50" />

      <SplitGridArea
        :size-unit="gridBottomUnit"
        :size-value="gridBottom"
        class="relative bg-gray-800"
      >
        <slot name="timeline" />
      </SplitGridArea>
    </SplitGrid>
  </template>

  <script>
  import {
    SplitGrid,
    SplitGridArea,
    SplitGridGutter
  } from 'vue-split-grid'

  export default {
    name: 'LayoutEditor',
    components: {
      SplitGrid,
      SplitGridArea,
      SplitGridGutter
    },

    props: {

      showTimeline: {
        type: Boolean,
        default: true
      },

      showLeft: {
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        baseL: 20,
        baseC: 80,

        baseTop: 78,
        baseBottom: 22,

        gridSize: null

      }
    },

    computed: {
      colSizeMin () {
        return 45
      },

      gridL () {
        if (this.showLeft) return this.baseL
        return 0
      },

      gridC () {
        if (this.showLeft) return 100 - this.baseL
        return 100
      },

      gridTopUnit () {
        if (this.showTimeline) return '%'
        return 'fr'
      },

      gridBottomUnit () {
        if (this.showTimeline) return '%'
        return 'px'
      },

      gridTop () {
        if (this.showTimeline) return this.baseTop
        return 1
      },

      gridBottom () {
        if (this.showTimeline) return this.baseBottom
        return 0
      },

      gridBottomMin () {
        return 32
      },

      showLeftGutter () {
        return this.showLeft
      }
    },

    mounted () {
      this.checkForStoredLayoutSizes()
    },

    methods: {
      onDragEnd (event) {
        this.$emit('grid-resized', event)
        this.updateStoredLayout()
      },

      updateStoredLayout () {
        let outer = this.$refs.outerGrid
        let inner = this.$refs.innerGrid

        let sizes = {
          outer: outer.$el.style.gridTemplateRows,
          inner: inner.$el.style.gridTemplateColumns
        }

        this.$store.dispatch('preferences/updateEditorLayoutSizes', {
          sizes: sizes
        })
      },

      checkForStoredLayoutSizes () {
        let stored = this.$store.getters['preferences/editorLayoutSizes']
        if (stored === null) return

        if (stored.inner !== undefined) {
          let parts = stored.inner.split(' ')
          if (parts.length !== 3) return

          // Make sure the BaseL + BaseC add up to around 100%
          let baseL = Number(parts[0].replace('%', ''))
          let baseC = Number(parts[2].replace('%', ''))

          if (!isNaN(baseL) && !isNaN(baseC)) {
            this.baseL = baseL
            this.baseC = baseC
          }
        }

        if (stored.outer !== undefined) {
          let parts = stored.outer.split(' ')
          if (parts.length !== 3) return

          let top = Number(parts[0].replace('%', '').replace('px', ''))
          let bottom = Number(parts[2].replace('%', '').replace('px', ''))

          if (!isNaN(top) && !isNaN(bottom)) {
            this.baseTop = top
            this.baseBottom = bottom
          }
        }
      }
    }
  }

  </script>
